import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from 'gatsby';

const SubCategoryTile = props => {
  const { item } = props;
  return (
    <div className='border-2 border-transparent hover:border-primary bg-white py-6 md:h-72'>
      <Link to={item.slug}>
        {item.image ?
          <GatsbyImage
            image={getImage(item?.image?.localFile)}
            objectFit="contain"
            className="h-28 md:h-44 px-4"
            alt={item.name}
          />
          :
          <div className='h-28 md:h-44 bg-tertiary px-4' />
        }

        <h4 className='md:text-lg text-primary text-center mt-2 px-3'>{item.name}</h4>
      </Link>
    </div>

  )
}

export default SubCategoryTile
